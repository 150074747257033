// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

global.jQuery, global.$ = require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("../plugins/bootstrap-table");
require("../plugins/bootstrap-table-ru-RU");

import 'bootstrap';
import 'bootstrap-notify';
import 'bootstrap-colorpicker';
import 'bootstrap-rating';
import 'select2';
import 'select2/dist/js/i18n/ru';
import 'chart.js';
import 'jssocials';
import 'jquery-touchswipe';
import 'summernote/dist/summernote-bs4.min';
import 'summernote/dist/lang/summernote-ru-RU.min';
import 'blueimp-file-upload';
import ImagesLoaded from 'imagesloaded';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import copy from 'copy-to-clipboard';
import IMask from 'imask';

jQueryBridget( 'imagesLoaded', ImagesLoaded, $ );
jQueryBridget( 'masonry', Masonry, $ );

function setCareerStudents() {
  $('.career-students').each(function(i, obj) {
    var item = $(obj);
    var height = item.outerHeight() / 2;
    item.css('bottom', -height + 'px');
    item.css('border-radius', height + 'px');
  });
}

function initMasonry() {
  var $container = $('.grid');

  $container.imagesLoaded(function () {
    $container.masonry({
      itemSelector: '.mentor',
      percentPosition: true,
      horizontalOrder: true
    });
  });
  var $container2 = $('.career-grid');

  $container2.imagesLoaded(function () {
    $container2.masonry({
      itemSelector: '.career-item',
      percentPosition: true,
      horizontalOrder: true
    });
  });
}

function initFileUpload() {
  $('.fileupload').fileupload();
}

function getTimeRemaining(endtime, delay) {
  var t = Date.parse(endtime) - Date.parse(new Date()) + delay;
  var seconds = Math.floor( (t/1000) % 60 );  
  var minutes = Math.floor( (t/1000/60) % 60 );  
  var hours = Math.floor( (t/(1000*60*60)) % 24 );  
  var days = Math.floor( t/(1000*60*60*24) );  
  return {  
   'total': t,  
   'days': days,  
   'hours': hours,  
   'minutes': minutes,  
   'seconds': seconds  
  };  
}

function initializeClock(id, endtime, delay){  
  var clock = document.getElementById(id);  
  var timeinterval = setInterval(function(){  
    var t = getTimeRemaining(endtime, delay);

    if (t.total < 60000) {
      clock.innerHTML = '<div class="red-clock xs-p-10 semi-bold gray">🔥 Осталось ' + ('0' + t.minutes).slice(-2) + ':' + ('0' + t.seconds).slice(-2) + '</div>';
    } else {
      clock.innerHTML = '<div class="gray-clock xs-p-10 semi-bold gray">⏱ Осталось ' + ('0' + t.minutes).slice(-2) + ':' + ('0' + t.seconds).slice(-2) + '</div>';      
    }
    if (t.total <= 0) {
      clock.innerHTML = '<div class="red-clock xs-p-10 semi-bold gray">🔥 Время вышло</div>';
      clearInterval(timeinterval);
      window.location.replace(window.location.origin + gon.path + '/challenges/' + gon.id +'/cancel');
    }  
  }, 1000);
}

function setBgTop() {
  if ($('.has-background').length) {
    $('.has-background').css('padding-top', $('.navbar.fixed-top').outerHeight(true) + 'px');
  }
}

$(function() {
  if (gon.endtime) {
    var delay = Date.parse(new Date()) - Date.parse(gon.starttime);
    initializeClock('clockdiv', gon.endtime, delay);
  }
  setCareerStudents();
  initMasonry();
  initFileUpload();
  setBgTop();

  $(window).resize(function () {
    setCareerStudents();
    setBgTop();
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('#navbarStudent').tooltip();

  $('a[data-toggle=tab]').each(function () {
		var $this = $(this);
		$this.on('shown.bs.tab', function () {
      $('.vacancy-grid').masonry({
        itemSelector: '.vacancy-item',
        percentPosition: true,
        horizontalOrder: true
      });
		}); //end shown
	});  //end each

  $('.select').select2({
    theme: 'bootstrap4',
    language: 'ru',
    minimumResultsForSearch: Infinity
  });

  $('.select_with_search').select2({
    theme: 'bootstrap4',
    language: 'ru'
  });

  $('.color').colorpicker({});

  $('.copy_input').on('click', () => {
    copy($('#url').val());
    $('.text-success').text('Cсылка скопирована')
  });

  $('#change_password').on('click', () => {
    $('#password_fields').removeClass('d-none');
  });

  $('#reg').on('click', () => {
    $('#user_ga_client_id').val(ga.getAll()[0].get('clientId'));    
  });

  $('#user_education, #user_city_id, #user_university_id, #user_why_here').on('change', () => {
    $('#profile_save').removeClass('btn-success');
    $('#profile_save').addClass('btn-outline-success');
  });

  $('.summernote').summernote({
    height: 300,
    lang: 'ru-RU',
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['height', ['height']]
    ]
  });

  $("#share").jsSocials({
    url:       $("#share").data('url'),
    showLabel: false,
    showCount: false,
    shares: [{
      share: "facebook",
      logo: '/images/fb.png'
    }, {
      share: "vkontakte",
      logo: '/images/vk.png'
    }]
  });

  $("#share-vk").jsSocials({
    url:       $("#share-vk").data('url'),
    showLabel: false,
    showCount: false,
    shares: [{
      share: "vkontakte",
      logo: '/images/vk.png'
    }]
  });

  $("#share-vk2").jsSocials({
    url:       $("#share-vk").data('url'),
    showLabel: false,
    showCount: false,
    shares: [{
      share: "vkontakte",
      logo: '/images/vk.png'
    }]
  });

  $("#share-vk3").jsSocials({
    url:       $("#share-vk").data('url'),
    showLabel: false,
    showCount: false,
    shares: [{
      share: "vkontakte",
      logo: '/images/vk.png'
    }]
  });

  $("#share-fb").jsSocials({
    url:       $("#share-fb").data('url'),
    showLabel: false,
    showCount: false,
    shares: [{
      share: "facebook",
      logo: '/images/fb.png'
    }]
  });

  $('#questionModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var course = button.data('course');
    var courseId = button.data('courseId');
    
    var modal = $(this)
    modal.find('.medium.gray.xs-mt-25 span').text('“' + course + '”');
    modal.find('.btn').attr("href", "/learning/courses/" + courseId);
  });

  $('#offerModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var offer = button.data('offer');
    
    var modal = $(this)
    modal.find('.medium.gray.xs-mt-25').html(offer);
  });

  $('#referralModal').on('show.bs.modal', function (event) {
    $('.img-man').removeClass('d-none');
  });

  $('#referralModal').on('hide.bs.modal', function (event) {
    $('.img-man').addClass('d-none');
  });  

  $("#tours").swipe( {
    swipeStatus:function(event, phase, direction, distance, duration, fingerCount, fingerData, currentDirection)
    {
      if (phase=="end"){ 
        //сработает через 20 пикселей то число которое выбрали в threshold
        const url = window.location.origin;
        const step = $('#tours').data('page');
        if (direction == 'left') {
          //сработает при движении влево
          if (step == 6) {
            window.location.replace(url + '/learning/users/' + step);
          } else {
            window.location.replace(url + '/learning/tours?step=' + (step + 1));
          }
        }
        if (direction == 'right') {
          //сработает при движении вправо
          if (step !== 1) {
            window.location.replace(url + '/learning/tours?step=' + (step - 1));
          }
        }
      }
    },
    triggerOnTouchEnd: false,
    threshold: 20 // сработает через 20 пикселей
  });

  $( document )
    .on("mousemove", ".col-course, .test-square, .col-certification, .program-square", function( e ) {
      //gradient angle and opacity
      var w = $(window).width(); //window width
      if ( w < 768 ) return;

      var h = $(window).height(), //window height
        dy = e.pageY - h / 2, //@h/2 = center of poster
        dx = e.pageX - w / 2, //@w/2 = center of poster
        theta = Math.atan2(dy, dx), //angle between cursor and center of poster in RAD
        angle = theta * 180 / Math.PI - 90, //convert rad in degrees
        $shine = $(this).find('.shine');
      const halfW = ( this.clientWidth / 2 );
      const halfH = ( this.clientHeight / 2 );

      const coorX = ( halfW - ( e.pageX - $(this).offset().left ) );
      const coorY = ( halfH - ( e.pageY - $(this).offset().top) );

      const degX  = ( ( coorY / halfH ) * 10 ) + 'deg'; // max. degree = 10
      const degY  = ( ( coorX / halfW ) * -10 ) + 'deg'; // max. degree = 10

      $(this).css('transform', function() {
        return 'perspective( 600px ) translate3d( 0, -2px, 0 ) scale(1.1) rotateX('+ degX +') rotateY('+ degY +')';
      });

      //get angle between 0-360
      if (angle < 0) {
        angle = angle + 360;
      }

      $shine.css('background', 'linear-gradient(' + angle + 'deg, rgba(255,255,255,' + e.pageY / h + ') 0%,rgba(255,255,255,0) 80%)');
    })
    .on("mouseout", ".col-course, .test-square, .col-certification, .program-square", function() {
      $(this).removeAttr('style');
      $(this).find('.shine').removeAttr('style');
    });

  $('#agree').click(function() {
    if ($(this).is(':checked')) {
      $('#responseModal .btn.btn-primary').removeClass('disabled');
    } else {
      $('#responseModal .btn.btn-primary').addClass('disabled');
    }
  });

	$("tr[data-link]").click(function() {
		window.location = $(this).data("link");
	});

  $('.btn-outline-challenge').on('click', () => {
    $('.edit_challenge_answer .btn.btn-primary').removeAttr('disabled');
  });

  $('#change-page').click(function() {
    $('table.bg-light.table').bootstrapTable('selectPage', $(this).data("page"))
  });
});